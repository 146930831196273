define(['app'], function(app) {

  class HeaderSearchThatScrollsToggle {

    constructor() {
      this.init = this.init.bind(this);
      this.handleScroll = this.handleScroll.bind(this);
    }

    init(element) {
      this.element = element;
      const headerSearchThatScrollsInput = element.querySelector('.headerSearchThatScrolls_input');
      headerSearchThatScrollsInput.addEventListener('focus', (e) => {
        app.publish('SHOW-HEADER-SEARCH-FORM', e.target.value);
        e.target.value = '';
      });
      headerSearchThatScrollsInput.addEventListener('input', (e) => {
        if (e.target.value) {
          app.publish('SHOW-HEADER-SEARCH-FORM', e.target.value);
          e.target.value = '';
        }
      });
      window.addEventListener('scroll', this.handleScroll);
      this.handleScroll();
    }

    handleScroll () {
      if (window.pageYOffset <= 50) {
        app.publish('HEADER-SEARCH-THAT-SCROLLS-VISIBLE', true);
      } else {
        app.publish('HEADER-SEARCH-THAT-SCROLLS-VISIBLE', false);
      }
    }

  }
  return HeaderSearchThatScrollsToggle;
});
